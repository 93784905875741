import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import './App.css';

import Landing from './components/Projects/Landing';
import Singlesale from './components/Research/Singlesale';
import Create from './components/Create/Create';
import Comingsoon from './components/Staking/Comingsoon';



function App() {
  return (
    <div>  
    <div><Toaster/></div>
      <Router basename={'/'}>
	      <section className='container-fluid px-0'>
	     
            <Route exact path='/' component={Landing} />
	      <Route exact path='/projects' component={Landing} />

            <Route exact path='/sale/:id' component={Singlesale} />
            <Route exact path='/create' component={Create} />

            < Route path = '/staking'
            component = {
              () => {
                alert("Coming Soon");
              }
            }
            />
	      </section>
      </Router>
    </div>
  );
}

export default App;
