import React, { Component } from "react";
import { useContext, useEffect, useState } from "react";

import Header from "../Header";

import Footer from "../Footer";
import Walletmodal from "../Walletmodal";
import Buymodal from "./Buymodal";
import Singlesalecard from "./Singlesalecard";
import Poolinformation from "./Poolinformation";
import Tokeninformation from "./Tokeninformation";
import Allocation from "./Allocation";
import Admin from "./Admin";

import Web3 from "web3";

import { Tab, Col, Nav, Row } from "react-bootstrap";
import "../../css/styles.css";

import coin from "../../images/coin.png";
import favicon from "../../images/favicon.png";
import {
  getSaleInfo,
  getSaleInfoCard,
  GetSalePerAccount,
} from "../../hooks/useContract";
import { getAccount, getAllocationInfo } from "../../hooks/useAccount";
import { addTokentoMetamask } from "../../hooks/useWeb3";
import { claimToken } from "../../hooks/useBuy";
import { isSaleLive, isUpcoming } from "../../hooks/useProjects";

import Countdown from "react-countdown";

const renderer = ({
  days,
  Month,
  Year,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  if (completed) {
    return (
      <div>
        <div>{days}d </div>
        <div>{hours}h</div>
        <div>{minutes}m</div>
        <div>{seconds}s </div>
      </div>
    );
  } else {
    // Render a countdown
    return (
      <div>
        {/* <span>{days}<span>Days</span> </span>
              <span>{hours}<span>Hours</span></span>
              <span>{minutes}<span>Minuits</span></span>   
              <span>{seconds}<span>Seconds</span> </span>    */}
        <div>{days}d </div>
        <div>{hours}h</div>
        <div>{minutes}m</div>
        <div>{seconds}s </div>
      </div>
    );
  }
};

class Singlesale extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    //  this.showLoader();
    // this.hideLoader();
    this.loadData();
  }
  constructor(props) {
    super(props);
    this.state = {
      buyModal: false,
      walletModal: false,
      saleAddress: this.props.location.pathname.split("/")[2],
      saleInfo: {},
      userInfo: {},
      allocations: [],
    };
  }

  async Claim() {
    await claimToken(this.state.saleAddress, getAccount());
    this.refreshData.bind(this);
  }

  async loadData() {
    this.showLoader();
    const saleDetail = await getSaleInfoCard(this.state.saleAddress);
    this.setState({ saleInfo: saleDetail });
    console.log("Sale  :", saleDetail);
    if (localStorage.getItem("accountInfo")) {
      const user = await GetSalePerAccount(
        localStorage.getItem("accountInfo"),
        this.state.saleAddress
      );
      this.setState({ userInfo: user });
      console.log("User : ", user);
      if (saleDetail.isClaimable) {
        const allocated = await getAllocationInfo(
          user.actualBalance,
          user.userClaimbale,
          saleDetail.vestingInterval,
          saleDetail.vestingPercent,
          user.initialClaim
        );
        this.setState({ allocations: allocated });
        console.log("allcations L ", allocated);
      }
    }
    this.hideLoader();
  }

  async refreshData() {
    const saleDetail = await getSaleInfoCard(this.state.saleAddress);
    this.setState({ saleInfo: saleDetail });
    console.log("On Update Sale  :", saleDetail);
    if (localStorage.getItem("accountInfo")) {
      const user = await GetSalePerAccount(
        localStorage.getItem("accountInfo"),
        this.state.saleAddress
      );
      this.setState({ userInfo: user });
      if (saleDetail.isClaimable) {
        const allocated = await getAllocationInfo(
          user.actualBalance,
          saleDetail.vestingInterval,
          saleDetail.vestingPercent,
          user.initialClaim
        );
        this.setState({ allocations: allocated });
      }
    }
  }

  AddTokenMetamask() {
    addTokentoMetamask(
      this.state.saleInfo.tokenAddress,
      this.state.saleInfo.symbol,
      this.state.saleInfo.decimals,
      this.state.saleInfo.logo
    );
  }

  render() {
    const location = this.props.location.pathname.split("/")[2];
    const { buyModal, walletModal, saleAddress, saleInfo } = this.state;
    console.log(saleInfo);
    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round"></span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className="logo_overlay" id="logo_overlay">
          <Header />
          <div className="whole_sec pb-5">
            {/* ongoing_sec */}
            <div className="ongoing_sec">
              <div className="inner_bg mt-4">
                <div className="container container_custom">
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-4">
                      <div>
                        <img
                          src={saleInfo && saleInfo.logo}
                          alt={saleInfo && saleInfo.symbol}
                          className="round_img"
                        />
                      </div>
                      <p className="text-white font_35 mb-0">
                        {saleInfo && saleInfo.name}
                      </p>
                      <p className="mt-2 mb-0">
                        {saleInfo &&
                        isSaleLive(
                          saleInfo.startTime,
                          saleInfo.endTime,
                          saleInfo.isPresaleOpen
                        ) ? (
                          <span className="badge infobtn badge-green badge-green-big mr-3">
                            <span className="green_dot"></span>
                            <span className="green_txt">Live</span>
                          </span>
                        ) : isUpcoming(saleInfo.startTime) ? (
                          <span className="badge upbtn badge-green badge-green-big mr-3">
                            <span className="green_dot"></span>
                            <span className="green_txt">Upcoming</span>
                          </span>
                        ) : (
                          <span className="badge dangerbtn badge-green badge-green-big mr-3">
                            <span className="green_dot"></span>
                            <span className="green_txt">Closed</span>
                          </span>
                        )}
                        {saleInfo && saleInfo.isWithoutToken ? (
                          <span className="badge badge-yellow-fill badge-yellow-fill-big mt-2">
                            <span className="text-white">
                              {saleInfo && saleInfo.symbol}
                            </span>
                          </span>
                        ) : (
                          <span className="badge badge-yellow-fill badge-yellow-fill-big mt-2">
                            <span className="text-white">
                              {saleInfo && saleInfo.symbol}
                            </span>
                          </span>
                        )}
                        <span
                          className="badge badge-yellow-fill badge-yellow-fill-big mt-2"
                          style={{ marginLeft: "10px" }}
                        >
                          <a
                            target={"_blank"}
                            href={
                              saleInfo && saleInfo.social && saleInfo.social[0]
                            }
                            className=""
                          >
                            <i
                              className="fab fas fa-globe"
                              style={{ color: "white" }}
                            ></i>
                          </a>
                        </span>
                        <span
                          className="badge badge-yellow-fill badge-yellow-fill-big mt-2"
                          style={{ marginLeft: "10px" }}
                        >
                          <a
                            target={"_blank"}
                            href={
                              saleInfo && saleInfo.social && saleInfo.social[1]
                            }
                            className=""
                          >
                            <i
                              className="fab fa-twitter"
                              style={{ color: "white" }}
                            ></i>
                          </a>
                        </span>

                        <span
                          className="badge badge-yellow-fill badge-yellow-fill-big mt-2"
                          style={{ marginLeft: "10px" }}
                        >
                          <a
                            target={"_blank"}
                            href={
                              saleInfo && saleInfo.social && saleInfo.social[2]
                            }
                            className=""
                          >
                            <i
                              className="fab fa fa-paper-plane"
                              style={{ color: "white" }}
                            ></i>
                          </a>
                        </span>
                      </p>
                      <p className="text-white mt-3 line_he_big">
                        {" "}
                        {saleInfo && saleInfo.description}{" "}
                      </p>

                      <p className="mt-4">
                        <button
                          className="get-started-btn-fill"
                          onClick={() => this.setState({ buyModal: true })}
                        >
                          Join
                        </button>
                        {this.state.saleInfo.isWithoutToken ? (
                          <></>
                        ) : (
                          <button
                            onClick={this.AddTokenMetamask.bind(this)}
                            className="get-started-btn-fill"
                            style={{ marginLeft: "10px" }}
                          >
                            Add Token to Metamask
                          </button>
                        )}
                        {saleInfo &&
                          !isSaleLive(
                            saleInfo.startTime,
                            saleInfo.endTime,
                            saleInfo.isPresaleOpen
                          ) &&
                          !isUpcoming(saleInfo.startTime) &&
                          !saleInfo.isWithoutToken &&
                          this.state.userInfo &&
                          (
                            parseInt(this.state.userInfo.actualBalance) /
                            10 ** parseInt(saleInfo.decimals)
                          ).toFixed(2) > 0 &&
                          saleInfo.isClaimable && (
                            <button
                              style={{
                                marginLeft: "10px",
                                fontWeight: "bold",
                                color: "white",
                              }}
                              onClick={this.Claim.bind(this)}
                              className="badge-green-fill mr-2 mb-2"
                            >
                              Claim
                            </button>
                          )}
                      </p>
                      {/* <p class="mt-2 countbtn salecount  mb-0">
                    <span class="badge upbtn badge-green">
                        <span class="green_dot"></span>
                        <span class="green_txt">
                        <div className='countdown d-flex align-items-center'>
                    Opens in &nbsp; <Countdown date={Date.now() + 1687600000} renderer={renderer} />
                </div> 
                            </span></span>
                </p> */}
                    </div>
                    <div className="col-12 col-lg-6">
                      <Singlesalecard
                        userData={this.state.userInfo}
                        saleData={this.state.saleInfo}
                        onUpdate={this.refreshData.bind(this)}
                        value="string"
                      />
                    </div>
                  </div>
                  <div className="mt-5 tab_div">
                    <Tab.Container defaultActiveKey="first">
                      <Row>
                        <Col lg={12}>
                          <Nav variant="pills" className="">
                            <Nav.Item>
                              <Nav.Link eventKey="first" id="first">
                                <p className="mb-0">Project Details</p>
                              </Nav.Link>
                            </Nav.Item>
                            {saleInfo &&
                            !saleInfo.isWithoutToken &&
                            this.state.allocations &&
                            this.state.allocations.length > 0 ? (
                              <Nav.Item>
                                <Nav.Link eventKey="second" id="second">
                                  <p className="mb-0">Your Allocation</p>
                                </Nav.Link>
                              </Nav.Item>
                            ) : (
                              <></>
                            )}
                            {saleInfo && saleInfo.owner == getAccount() ? (
                              <Nav.Item>
                                <Nav.Link eventKey="third" id="third">
                                  <p className="mb-0">Admin</p>
                                </Nav.Link>
                              </Nav.Item>
                            ) : (
                              <></>
                            )}
                          </Nav>
                        </Col>
                        <Col lg={12} className="img_center_lg">
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="tab_img mt-5">
                                <div className="row">
                                  <div className="col-12 col-lg-6 mb-4">
                                    <Poolinformation
                                      saleInfo={this.state.saleInfo}
                                      value="string"
                                    />
                                  </div>

                                  <div className="col-12 col-lg-6 mb-4">
                                    <Tokeninformation
                                      saleData={this.state.saleInfo}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="tab_img mt-5">
                                <div className="row">
                                  <div className="col-12 col-lg-12 mb-4">
                                    <div className="text-right mb-4">
                                      {this.state.saleInfo.isWithoutToken ? (
                                        <></>
                                      ) : (
                                        <button
                                          onClick={this.AddTokenMetamask.bind(
                                            this
                                          )}
                                          className="get-started-btn-fill"
                                        >
                                          Add Token to Metamask
                                        </button>
                                      )}
                                    </div>

                                    <Allocation
                                      userData={this.state.userInfo}
                                      allocationData={this.state.allocations}
                                      saleAddress={this.state.saleAddress}
                                      saleData={this.state.saleInfo}
                                      onUpdate={this.refreshData.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            {/* { saleInfo && saleInfo.owner== getAccount()?  */}
                            <Tab.Pane eventKey="third">
                              <div className="tab_img mt-5">
                                <Admin
                                  saleAddress={this.state.saleAddress}
                                  onUpdate={this.refreshData.bind(this)}
                                  userData={this.state.userInfo}
                                  saleData={this.state.saleInfo}
                                />
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
            {/* end ongoing_sec */}
          </div>
          {walletModal && <Walletmodal connect={"string"} />}
          {buyModal && (
            <Buymodal
              userData={this.state.userInfo}
              saleAddress={this.state.saleAddress}
              saleData={this.state.saleInfo}
              onUpdate={this.refreshData.bind(this)}
              onDismiss={() => this.setState({ buyModal: false })}
              connect={"string"}
            />
          )}

          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

export default Singlesale;