import { ERC20_ABI, ERC20_ADDRESS, DECIMAL } from "../config/erc20";
import { PROXY_SALE,PROXY_SALE_ABI } from '../config/proxy'
import { SALE_ABI } from '../config/presale'
import toast, { Toaster } from 'react-hot-toast';
import { useWeb3 } from "./useWeb3";
import { getSaleInfo, getSaleInfoCard, UseProxySale, UseSale } from "./useContract";
import { ONEDAYINSECONDS } from "../config/env";

export const getTotalSalesInfo = async()=>{
    try{
    const proxy = await UseProxySale();
    let data = await proxy.methods.getTotalSales().call();
    // const initial = [{
    //     _sale  : "0xA802274ba245171A37d39beC1Fd01cF459A8aeA8",
    //     _start : 142108977862384,
    //     _end : 142116442822384,
    //     _name : "Metaverse Lab"
    // }];
    // data = (initial).concat(data);
  console.log("Proxy : data :",data)
    const reverseArray = [...data].reverse();
    return (reverseArray);
    }
    catch(e){
        console.log("Error : ",e);
        return [];
    }
   
}

export const getSaleCards = async (cards)=>{
    let SaleInfoCards = [];
    await cards.map( async(index)=>{
        const saleInfo = await getSaleInfoCard(index._sale);
        SaleInfoCards.push(saleInfo);
    })
    return SaleInfoCards;
}

export const getSaleCardsLimit = async (cards,start,end)=>{
    let SaleInfoCards = [];
    console.log("Cards data : ",cards,start,end)
    if(cards.length > 0){
    for(var i=start;i<end;i++){
        if(i<cards.length){
            const saleInfo = await getSaleInfoCard(cards[i]._sale);
            SaleInfoCards.push(saleInfo);
        }    
    }
    
    }
    return SaleInfoCards;
}

export const searchSale = async(SaleInfoCards,search) =>{
   const filteredData = await SaleInfoCards.filter(value => ((value._name).toUpperCase()).includes(search.toUpperCase()));
  // console.log("Sale filteredData : ",filteredData)
   let filteredCard = [];
   await filteredData.map( async(index)=>{
    const saleInfo = await getSaleInfoCard(index._sale);
    filteredCard.push(saleInfo);
    })
    console.log("Sale filteredCard : ",filteredCard)
   return filteredCard;
}

export const searchCards = async(SaleInfoCards, search) => {
    const filteredData = await SaleInfoCards.filter(value => ((value._name).toUpperCase()).includes(search.toUpperCase()));
    return filteredData;
}

export const isSaleLive = (start,end,isOpen) => {
    return (Date.now() >= (start*1000) && Date.now()<= (end*1000)) && isOpen;
}

export const isUpcoming = (start,end) =>{
    return (Date.now() < (start*1000));
}

export const isSaleEnded = (start,end,isOpen) => {
    return (Date.now() >= (end*1000)) || !isOpen
}

export const UpcomingDiffernce = (start) => {
    return ((start*1000) - Date.now());
}

export const Salediffernce = (end)=>{
    return ((end*1000)-Date.now());
}


export const processCSV = (str, delim=',') => {
    const headers = str.slice(0,str.indexOf('\n')).split(delim);
    const rows = str.slice(str.indexOf('\n')+1).split('\n');

    const newArray = rows.map( row => {
        const values = row.split(delim);
        const eachObject = headers.reduce((obj, header, i) => {
            obj[header] = values[i];
            return obj;
        }, {})
        return eachObject;
    })

    return (newArray)
}

