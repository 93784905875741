import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { ProgressBar } from 'react-bootstrap';
import { isSaleLive, isUpcoming, Salediffernce, UpcomingDiffernce } from '../../hooks/useProjects';
import Countdown from 'react-countdown';
import coin from "../../images/coin.png";

const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return  <div>
      <div>{days}d </div>
      <div>{hours}h</div>
      <div>{minutes}m</div>   
      <div>{seconds}s </div>         
   </div>
    } else {
      // Render a countdown
      return <div>
                {/* <span>{days}<span>Days</span> </span>
                <span>{hours}<span>Hours</span></span>
                <span>{minutes}<span>Minuits</span></span>   
                <span>{seconds}<span>Seconds</span> </span>    */}
                 <div>{days}d </div>
      <div>{hours}h</div>
      <div>{minutes}m</div>   
      <div>{seconds}s </div>
            </div>;
    }
  };

class Buycard extends Component
{
    constructor(props) {
        super(props);
        
    }
    render() {
  
        const { saleData } = this.props;
      return (
          
          <div className='col-12 col-lg-6 col-lg-6-custom mb-4'>
              <a href={`/sale/${saleData && saleData.saleAddress}`}>
                  
        <div className='card card_style_1 ribbox'>
            {saleData && saleData.isWithoutToken ? 
        <div className='ribbon'>MANUAL</div>:<></>
            }
            <div className='card-body' style={{fontWeight: 'bold'}}>
                <div className='grid_img_div'>
                    <div>
                <img src={saleData && saleData.logo} alt={saleData && saleData.symbol} />
                </div>
                <div>
                    <p className='font_20' style={{color: "white"}}>
                        {saleData && saleData.name}
                        <span className="badge badge-yellow-fill mt-2" style={{marginLeft: "10px"}}>
                            <span style={{color: 'white'}}>{saleData && saleData.symbol}</span>
                        </span>
                    </p>
                    <div className="btn-group btn_grp_yel" role="group" aria-label="Basic example">
                    <a target={'_blank'} href={saleData && saleData.social[0]} className="btn btn-secondary">
                    <i className="fas fa-globe"></i>
                    </a>
                    <a target={'_blank'} href={saleData && saleData.social[1]} className="btn btn-secondary">
                    <i className="fab fa-twitter"></i>
                    </a>
                    <a target={'_blank'} href={saleData && saleData.social[2]} className="btn btn-secondary">
                    <i className="fa fa-paper-plane"></i>
                    </a>
                    </div>
                    <div className='d-sm-flex d-block' style={{margin: '5px 0px 5px 0px'}}>
                    <p className='mt-2 mb-0'>
                    {saleData && isSaleLive(saleData.startTime,saleData.endTime,saleData.isPresaleOpen)  ?
                             
                    <span className="badge infobtn badge-green">
                        <span className='green_dot'></span>
                        <span className='green_txt'>Live</span>
                    </span>:
                    ( saleData && isUpcoming(saleData.startTime) ?
                     <span className="badge upbtn badge-green">
                     <span className='green_dot'></span>
                     <span className='green_txt'>Upcoming</span>
                 </span>: <span className="badge dangerbtn badge-green">
                     <span className='green_dot'></span>
                     <span className='green_txt'>Closed</span>
                 </span>)
                }
                    </p>
                    {  saleData && isUpcoming(saleData.startTime) ?
                    <p class="mt-2 countup countbtn ml-sm-2 mb-0">
                    <span class="badge upbtn badge-green">
                        <span class="green_dot"></span>
                        <span class="green_txt">
                        <div className='countdown d-flex align-items-center'>
                    Opens in &nbsp; <Countdown date={Date.now() + (UpcomingDiffernce(saleData.startTime))} renderer={renderer} />
                </div> 
                            </span></span>
                </p>: (saleData && isSaleLive(saleData.startTime,saleData.endTime,saleData.isPresaleOpen)  ?
                <p class="mt-2 countbtn ml-sm-2 mb-0">
                    <span class="badge infobtn badge-green">
                        <span class="green_dot"></span>
                        <span class="green_txt">
                        <div className='countdown d-flex align-items-center'>
                    Ends in &nbsp; <Countdown date={Date.now() + Salediffernce(saleData.endTime)} renderer={renderer} />
                </div> 
                            </span></span>
                </p>:<></>)
                }

                </div>
                </div>
 
           
                </div>
      
               
                <p className='yellow_txt_sm' style={{marginTop: '10px', color: "white"}}>{saleData && (saleData.description).substring(0,130)}...</p>
        
          <div className='row'>
                <div className='col-12 col-lg-6 mb-3'>
                <p className='yellow_txt_sm mb-1 font_16'>Presale Rate</p>
                <p className='yellow_txt_sm font_16 pb-0 mb-0' style={{color: 'white'}}>1 ETH = {saleData && saleData.presaleRate} {saleData && saleData.symbol}</p>


                </div>
                <div className='col-12 col-lg-6 mb-3'>
                <p className='yellow_txt_sm mb-1 font_16'>Hard Cap</p>
                <p className='yellow_txt_sm font_18 pb-0 mb-0' style={{color: 'white'}}>{saleData && (saleData.hardCap/10**18).toFixed(2)} ETH</p>
               


                </div>
                {/* <div className='col-12 col-lg-4 mb-3'>
                <p className='yellow_txt_sm mb-1'>Access</p>
                <p className='yellow_txt_sm font_16 pb-0 mb-0'>{saleData && saleData.isWhitelisted ? 'Private' : 'Public'}</p>


                </div> */}
            </div>
            <p className='yellow_txt_sm d-flex justify-content-between'>
               <span>Progress</span>
               <span>Participants<b className='pl-2'>{saleData && saleData.participants}</b></span>

                </p>
            <ProgressBar now={saleData && Math.ceil((saleData.earnedCap)/(saleData.hardCap) * 100)} className='yellow_bar'/>
            <p className='white_txt_sm d-flex justify-content-between mt-3'>
               <span> {saleData && Math.ceil((saleData.earnedCap)/(saleData.hardCap) * 100)} %</span>
               <span>{saleData && ((saleData.earnedCap)/10**18).toFixed(3)}</span>

                </p>
            </div>
        </div>
            </a>
         

    </div>
    
      )
    }

}


export default Buycard