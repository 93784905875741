import ropsten from "../images/ropsten.png"
export const RPC_URL = "https://mainnet.infura.io/v3/e8749730a66b419a969918abb7a23a9e";
export const CHAIN_ID = 1;
  
// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
//  export const CHAIN_ID = 97;
 export const ROUTER = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

 export const ONEDAYINSECONDS = 86400;


 export const CHAINS = [
    // {
    //     NAME: "BSC",
    //     RPC_URL:"https://bsc-dataseed1.binance.org/",
    //     CHAIN_ID:56,
    //     ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E"
    // },
    // {
    //     NAME: "Arbitrum One",
    //     RPC_URL:"https://arb1.arbitrum.io/rpc",
    //     CHAIN_ID:42161,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x5649B4DD00780e99Bab7Abb4A3d581Ea1aEB23D0" // KyberSwap
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    // },
    // {
    //     NAME: "zkSync Era Mainnet",
    //     RPC_URL: "https://mainnet.era.zksync.io",
    //     CHAIN_ID: 324,
    //     ROUTER: "0x5649B4DD00780e99Bab7Abb4A3d581Ea1aEB23D0" // KyberSwap
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    // }

    // {
    //     NAME: "BSC-TEST",
    //     RPC_URL:"https://data-seed-prebsc-1-s1.binance.org:8545/",
    //     CHAIN_ID:97,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3" //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    // },
    {
        NAME: "ETH",
        RPC_URL:"https://mainnet.infura.io/v3/e8749730a66b419a969918abb7a23a9e",
        CHAIN_ID: 1,
        ROUTER: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"
    }
//   {
//         NAME: "ROPSTEN",
//         RPC_URL:"https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
//         CHAIN_ID:3,
//         IMAGE: ropsten,
//         ROUTER: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"
//     }
];